import React, { useState } from 'react';
import { addDoc, collection } from 'firebase/firestore';
import { db } from './Firebase';
import axios from "axios";
import { PERSPECTIVE_API_URL } from "./App";

function CommentForm() {
    const [input, setInput] = useState('');
    const [username, setUsername] = useState('');

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        if (!username || !input) {
            alert("Both username and comment fields are required!");
            return;
        }

        // Log the data that you're sending to the API
        console.log("Sending data to Perspective API:", {
            comment: { text: input },
            languages: ["en"],
            requestedAttributes: {
                TOXICITY: {},
                INSULT: {},
                FLIRTATION: {},
                THREAT: {}
            }
        });

        // Call the Perspective API and push results to Firebase
        try {
            const res = await axios.post(PERSPECTIVE_API_URL, {
                comment: { text: input },
                languages: ["en"],
                requestedAttributes: {
                    TOXICITY: {},
                    INSULT: {},
                    FLIRTATION: {},
                    THREAT: {}
                }
            });

            console.log("Response from Perspective API:", res.data); // Log the entire response

            const toxic = 100 * res.data.attributeScores.TOXICITY.summaryScore.value;
            const insult = 100 * res.data.attributeScores.INSULT.summaryScore.value;
            const flirt = 100 * res.data.attributeScores.FLIRTATION.summaryScore.value;
            const threat = 100 * res.data.attributeScores.THREAT.summaryScore.value;

            const payLoad = {
                user: username,
                post: input,
                filter1: toxic,
                filter2: insult,
                filter3: flirt,
                filter4: threat
            };

            console.log("Payload being sent to Firestore:", payLoad); // Log the payload
           

            const collectionref = collection(db, 'posts');
            await addDoc(collectionref, payLoad);

            console.log("Data successfully written to Firestore!");

            // Reset input fields after submission
            setInput('');
            setUsername('');

        } catch (error) {
            console.error("Error making API call: ", error);
            if (error.response) {
                // If there's a response object in the error, log it as well.
                console.error("Response data from error:", error.response.data);
            }
        }
    };

    return (
        <div className="commentForm">
            <form onSubmit={handleFormSubmit}>
                <label htmlFor="username-input">Username</label>
                <input
                    id="username-input"
                    value={username}
                    onChange={e => setUsername(e.target.value)}
                    placeholder="Enter your username"
                    type="text"
                />

                <label htmlFor="comment-input">Comment</label>
                <input
                    id="comment-input"
                    value={input}
                    onChange={e => setInput(e.target.value)}
                    placeholder="Enter your respectful comment here"
                    type="text"
                />

                <button className="CommentForm__Submit" type="submit">
                    Submit
                </button>
            </form>
        </div>

    );
}

export default CommentForm;
