import React from "react";
import Feed from "./Feed";
import Header from "./Header";

// Consider moving this to an environment variable if it may change
export const PERSPECTIVE_API_URL =
    process.env.REACT_APP_PERSPECTIVE_API_URL ||
    "https://commentanalyzer.googleapis.com/v1alpha1/comments:analyze?key=AIzaSyC7ZdB66-GcKQCz64MwnFz_9VpYnNp3gHw";

class App extends React.Component {
    render() {
        return (
            <div className="App">
                {/* Header */}
                <Header />
                {/* Feed */}
                <Feed />
                {/* Footer placeholder (add footer component later) */}
                <div className="footer-placeholder"></div>
            </div>
        );
    }
}

export default App;
