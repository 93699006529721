// src/components/CommentForm.js

import React from "react";


function Rectangle({ width }) {
    return <span><b>{Math.round(width)}%</b></span>;  // Rounding the number to ensure it's an integer.
}


export default Rectangle;
