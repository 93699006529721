import React, { useEffect, useState } from "react";
import { db } from "./Firebase";
import Post from "./Post";
import { onSnapshot, collection } from 'firebase/firestore';
import CommentForm from "./CommentForm";

function Feed() {
    const [posts, setPosts] = useState([]);

    useEffect(() => {
        const unsubscribe = onSnapshot(collection(db, "posts"), (snapshot) => {
            setPosts(snapshot.docs.map((doc) => ({
                data: doc.data(),
                id: doc.id,
            })));
        });
        return () => unsubscribe();
    }, []);

    return (
        <div className="feed">
            <CommentForm />
            <div className="feedResults">
                <ul>
                    {posts.map(({ id, data: { user, post, filter1, filter2, filter3, filter4 } }) => (
                        <Post
                            key={id}
                            username={user}
                            post={post}
                            toxic={filter1}
                            flirt={filter3}
                            danger={filter4}
                            insults={filter2}
                        />
                    ))}
                </ul>
            </div>
        </div>
    );
}

export default Feed;
