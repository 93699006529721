import React from 'react'
import "./Header.css";


function header() {
    return (
        <div className="header">
            <h1>Creating Healthy conversations </h1>
            
        </div>
    )
}

export default header
