import "./Post.css";
import Avatar from "./Avatar";
import ThumbsUpAltOutlinedIcon from "@material-ui/icons/ThumbDownAltOutlined";
import Rectangle from './Rectangle'
import InputOptions from "./InputOptions";

function Post({ username, post, toxic, flirt, danger, insults }) {
    return (
        <div className="row">
            <div className="post">
                <div className="post_header">
                    <h3>Name: {username}</h3>
                </div>
                <div className="post_body">
                    <div className="postContent">
                        <p>{post}</p>
                    </div>
                    <div className="results">
                        <div>
                            Toxic:
                            <Rectangle
                                color="#3b9b6c"
                                width={parseInt(toxic)}
                            />
                        </div>
                        <div>
                            Flirty:
                            <Rectangle
                                color="#efacd8"
                                width={parseInt(flirt)}
                            />
                        </div>
                        <div>
                            Threatening:
                            <Rectangle
                                color="#d63555"
                                width={parseInt(danger)}
                            />
                        </div>
                        <div>
                            Insults:
                            <Rectangle
                                color="#dde047"
                                width={parseInt(insults)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};




export default Post;
