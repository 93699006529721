// src/index.js

import React from "react";
import ReactDOM from "react-dom";

import "./style.css";
import App from "./components/App";

<h2><p>[Update 03-04-2021] Icons with animations to be added soon!</p>  </h2>

ReactDOM.render(<App />, document.getElementById("root"));